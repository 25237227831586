import { USER_MENU_LINKS } from "@/constants/header-menu-items";
import { PATH_NAME } from "@/constants/path-name.constant";
import { useAuthStore } from "@/providers/auth-store-provider";
import { formatCurrency } from "@/utils/formatData";
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import NextImage from "../../NextImage";

const UserMenuDropdown = () => {
  const { logout, userInfo, refreshUserBalance, isFetchingBalance } =
    useAuthStore((state) => state);
  const router = useRouter();
  const t = useTranslations();

  const handleLogout = async () => {
    await logout();
    router.push(PATH_NAME.SIGN_IN);
  };

  return (
    <div
      className={clsx("dropdown-menu list-group position-absolute end-0")}
      style={{ top: "23px" }}
    >
      <div className="user-item">
        <NextImage
          src="/assets/img/user/user-05.jpg"
          alt="Profile"
          width={50}
          height={50}
        />
        <div className="user-name">
          <h6>{userInfo?.displayName}</h6>
          <p>
            {t("common.joinOn")} :{" "}
            {dayjs(userInfo?.createdOn).format("DD MMM YYYY")}
          </p>
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0">
              {t("common.balance")} : {formatCurrency(userInfo?.balance ?? 0)}
            </p>
            <button
              className="btn btn-link text-dark p-0"
              onClick={refreshUserBalance}
              disabled={isFetchingBalance}
            >
              <i
                className={`fas fa-sync ${isFetchingBalance ? "fa-spin" : ""}`}
              />
            </button>
          </div>
        </div>
      </div>

      {USER_MENU_LINKS.map((link) => {
        if (link.label === "menu.logout") {
          return (
            <div
              key={link.path}
              className={clsx("dropdown-item", link.className)}
              onClick={handleLogout}
            >
              <NextImage
                src={link.icon}
                className="img-fluid"
                alt="icon"
                width={20}
                height={20}
              />
              {t(link.label)}
            </div>
          );
        }
        return (
          <button
            key={link.path}
            className={clsx("dropdown-item", link.className)}
            onClick={() => router.push(link.path)}
          >
            <NextImage
              src={link.icon}
              className="img-fluid"
              alt="icon"
              width={20}
              height={20}
            />
            {t(link.label)}
          </button>
        );
      })}
    </div>
  );
};

export default UserMenuDropdown;
