import Image from "next/image";
import Link from "next/link";
import { NavigationItems } from "./NavigationItems";

interface MainMenuProps {
  toggleModal: () => void;
  pathname: string;
}

export const MainMenu = ({ toggleModal, pathname }: MainMenuProps) => (
  <div className="main-menu-wrapper">
    <div className="menu-header">
      <Image
        src="/assets/img/logo.png"
        width={180}
        height={50}
        className="img-fluid"
        alt="Logo"
        priority
      />
      <Link
        id="menu_close"
        className="menu-close"
        href="#"
        onClick={toggleModal}
      >
        <i className="fas fa-times" />
      </Link>
    </div>
    <NavigationItems pathname={pathname} />
  </div>
);
