"use client";
import { PATH_NAME } from "@/constants/path-name.constant";
import Link from "next/link";
import { useRouter } from "next/navigation";
import NextImage from "../../NextImage";

interface LogoProps {
  toggleModal: () => void;
}

export const Logo = ({ toggleModal }: LogoProps) => {
  const router = useRouter();

  return (
    <div className="navbar-header">
      <Link id="mobile_btn" href="#" onClick={toggleModal}>
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>

      <NextImage
        src="/assets/img/logo.png"
        width={180}
        height={50}
        className="img-fluid"
        alt="Logo"
        priority
        onClick={() => {
          router.push(PATH_NAME.HOME);
        }}
      />
    </div>
  );
};
