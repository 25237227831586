"use client";
import { PATH_NAME } from "@/constants/path-name.constant";
import { useTranslations } from "next-intl";
import Link from "next/link";
import NextImage from "../NextImage";

const socialLinks = [
  { icon: "fa-facebook", url: "#" },
  { icon: "fa-x-twitter", url: "#" },
  { icon: "fa-instagram", url: "#" },
  { icon: "fa-google", url: "#" },
  { icon: "fa-youtube", url: "#" },
];

const Footer = () => {
  const t = useTranslations("footer");

  const companyLinks = [
    { label: t("company.aboutUs"), path: PATH_NAME.ABOUT_US },
    { label: t("company.faq"), path: PATH_NAME.FAQ },
  ];

  const featuredCategories = {
    left: [
      t("categories.left.programming"),
      t("categories.left.music"),
      t("categories.left.lifestyle"),
      t("categories.left.photography"),
      t("categories.left.business"),
    ],
    right: [
      t("categories.right.ebook"),
      t("categories.right.aiArtists"),
      t("categories.right.aiServices"),
      t("categories.right.data"),
      t("categories.right.consulting"),
    ],
  };

  return (
    <footer className="footer">
      <div className="section-bg">
        <NextImage
          src="/assets/img/bg/footer-bg-01.png"
          className="footer-bg-one"
          width={100}
          height={100}
          alt="img"
        />
        <NextImage
          src="/assets/img/bg/footer-bg-02.png"
          className="footer-bg-two"
          width={100}
          height={100}
          alt="img"
        />
      </div>
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <div className="footer-widget">
                <Link href={PATH_NAME.HOME}>
                  <NextImage
                    src="/assets/img/logo.png"
                    alt="logo"
                    width={180}
                    height={70}
                  />
                </Link>
                <p>{t("mission")}</p>
                <div className="social-links">
                  <ul>
                    {socialLinks.map((social, index) => (
                      <li key={index}>
                        <Link href={social.url}>
                          <i className={`fa-brands ${social.icon}`} />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-2 col-md-6 col-sm-6 me-auto"
              data-aos="fade-up"
              data-aos-delay={600}
            >
              <div className="footer-widget">
                <h3>{t("sections.company")}</h3>
                <ul className="menu-items">
                  {companyLinks.map((link, index) => (
                    <li key={index}>
                      <Link href={link.path}>{link.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay={700}
            >
              <div className="footer-widget">
                <h3>{t("sections.featuredCategories")}</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="menu-items">
                      {featuredCategories.left.map((category, index) => (
                        <li key={index}>
                          <Link href={PATH_NAME.CATEGORIES}>{category}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="menu-items extra-menu">
                      {featuredCategories.right.map((category, index) => (
                        <li key={index}>
                          <Link href={PATH_NAME.CATEGORIES}>{category}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-widget">
            <div className="row align-items-center">
              <div className="col-xl-9">
                <ul className="location-list">
                  <li>
                    <span>
                      <i className="feather icon-map-pin" />
                    </span>
                    <div className="location-info">
                      <h6>{t("contact.address.label")}</h6>
                      <p>{t("contact.address.value")}</p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <i className="feather icon-phone" />
                    </span>
                    <div className="location-info">
                      <h6>{t("contact.phone.label")}</h6>
                      <p>{t("contact.phone.value")}</p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <i className="feather icon-mail" />
                    </span>
                    <div className="location-info">
                      <h6>{t("contact.email.label")}</h6>
                      <p>{t("contact.email.value")}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 text-xl-end">
                <div className="paypal-icons">
                  <Link href="#">
                    <NextImage
                      src="/assets/img/icons/stripe-icon.svg"
                      alt="icon"
                      width={100}
                      height={100}
                    />
                  </Link>
                  <Link href="#">
                    <NextImage
                      src="/assets/img/icons/paypal-icon.svg"
                      alt="icon"
                      width={100}
                      height={100}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="copy-right">
                <p>{t("copyright")}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-bottom-links">
                <ul>
                  <li>
                    <Link href={PATH_NAME.PRIVACY_POLICY}>
                      {t("privacyPolicy")}
                    </Link>
                  </li>
                  <li>
                    <Link href={PATH_NAME.TERM_CONDITION}>
                      {t("termsAndConditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
