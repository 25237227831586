"use client";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useState } from "react";

interface MenuItem {
  key: string;
  label: string;
  type: "link" | "dropdown";
  path?: string;
  items?: {
    label: string;
    path: string;
  }[];
}

interface NavigationItemProps {
  item: MenuItem;
  pathname: string;
}

export const NavigationItem = ({ item, pathname }: NavigationItemProps) => {
  const t = useTranslations();
  const isCurrentPath =
    item.path === pathname ||
    item.items?.some((subItem) => subItem.path === pathname);

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  if (item.type === "link") {
    return (
      <li className="nav-item">
        <Link
          href={item.path || "#"}
          className={`nav-link ${isCurrentPath ? "active" : ""}`}
        >
          {t(item.label)}
        </Link>
      </li>
    );
  }

  return (
    <li
      className="has-submenu"
      onMouseEnter={() => setIsSubmenuOpen(true)}
      onMouseLeave={() => setIsSubmenuOpen(false)}
    >
      <Link
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsSubmenuOpen(!isSubmenuOpen);
        }}
        className={`has-submenu ${isCurrentPath ? "active" : ""}`}
      >
        {t(item.label)} <i className="fas fa-chevron-down" />
      </Link>

      <ul
        className="submenu"
        style={{ display: isSubmenuOpen || isCurrentPath ? "block" : "none" }}
      >
        {item.items?.map((subItem) => (
          <li key={subItem.path}>
            <Link
              href={subItem.path}
              className={pathname === subItem.path ? "active" : ""}
            >
              {t(subItem.label)}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};
