import { useAuthStore } from "@/providers/auth-store-provider";
import { AuthNav } from "./AuthNav";
import { UserDashboardNav } from "./UserDashboardNav";

export const RightNavigation = () => {
  const { userInfo } = useAuthStore((state) => state);
  return (
    <>{userInfo ? <UserDashboardNav userInfo={userInfo} /> : <AuthNav />}</>
  );
};
