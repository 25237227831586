"use client";
import NextImage from "@/components/Common/NextImage";
import Link from "next/link";
import { useEffect, useState } from "react";

const BackToTop = () => {
  const [show, setShow] = useState(false);

  const scrollToTop = () => {
    window?.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window?.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window?.addEventListener("scroll", handleScroll);

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`back-to-top`}>
      <Link
        onClick={scrollToTop}
        className={`back-to-top-icon align-items-center justify-content-center d-flex ${
          show ? "show" : ""
        }`}
        href="#"
      >
        <NextImage
          src="/assets/img/icons/arrow-badge-up.svg"
          alt="img"
          width={18}
          height={16}
        />
      </Link>
    </div>
  );
};

export default BackToTop;
