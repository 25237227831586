import { PATH_NAME } from "@/constants/path-name.constant";

interface MenuItem {
  key: string;
  label: string;
  type: "link" | "dropdown";
  path?: string;
  items?: {
    label: string;
    path: string;
  }[];
}

export const MENU_ITEMS: MenuItem[] = [
  {
    key: "home",
    label: "menu.home",
    path: PATH_NAME.HOME,
    type: "link",
  },
  {
    key: "tasks",
    label: "menu.tasks",
    path: PATH_NAME.TASKS,
    type: "link",
  },
  // {
  //   key: "createTask",
  //   label: "text.addNewTask",
  //   path: PATH_NAME.ADD_TASK,
  //   type: "link",
  // },
  // {
  //   key: "teams",
  //   label: "menu.teams",
  //   path: PATH_NAME.TEAM,
  //   type: "link",
  // },
  // {
  //   key: "blog",
  //   label: "menu.blog",
  //   path: PATH_NAME.BLOG_LIST,
  //   type: "link",
  // },
  {
    key: "dashboard",
    label: "menu.dashboard",
    type: "dropdown",
    items: [
      { label: "menu.dashboard", path: PATH_NAME.DASHBOARD },
      { label: "menu.manage-task-post", path: PATH_NAME.USER_TASKS },
      { label: "menu.manage-task-apply", path: PATH_NAME.USER_APPLY_TASK },
      { label: "menu.my-reviews", path: PATH_NAME.USER_REVIEW },
      // { label: "menu.wishlist", path: PATH_NAME.USER_WISHLIST },
    ],
  },
  {
    key: "contact",
    label: "menu.contact",
    path: PATH_NAME.CONTACT_US,
    type: "link",
  },
  {
    key: "about",
    label: "menu.about",
    path: PATH_NAME.ABOUT_US,
    type: "link",
  },
  {
    key: "upgrade",
    label: "menu.upgrade",
    path: PATH_NAME.UPGRADE,
    type: "link",
  },
];

export const USER_MENU_LINKS = [
  {
    label: "menu.dashboard",
    path: PATH_NAME.DASHBOARD,
    icon: "/assets/img/icons/dashboard-icon-01.svg",
    className: "drop-line",
  },
  {
    label: "menu.wallet",
    path: PATH_NAME.USER_WALLET,
    icon: "/assets/img/icons/dashboard-icon-09.svg",
  },
  {
    label: "menu.settings",
    path: PATH_NAME.USER_SETTINGS,
    icon: "/assets/img/icons/settings-cog.svg",
    className: "drop-line",
  },
  {
    label: "menu.profile",
    path: PATH_NAME.USER_PROFILE,
    icon: "/assets/img/icons/user-cog.svg",
  },
  {
    label: "menu.logout",
    path: PATH_NAME.HOME,
    icon: "/assets/img/icons/logout.svg",
    className: "log-out",
  },
];
