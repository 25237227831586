import { PATH_NAME } from "@/constants/path-name.constant";
import {
  getLogoutApi,
  getRefreshUserBalanceApi,
  getUserInfoApi,
  postLoginApi,
  postRegisterApi,
} from "@/services/auth.service";
import type {
  ILoginParams,
  IRegisterParams,
  IUserInfo,
} from "@/types/auth.types";
import { removeCookie, setCookie } from "@/utils/cookies";
import { create, StateCreator } from "zustand";
import { persist } from "zustand/middleware";

export interface AuthState {
  isFetchingBalance: boolean;
  token: string | undefined;
  refreshToken: string | undefined;
  userInfo?: IUserInfo;
  isAuthenticated: boolean;
  isInitialized: boolean;

  login: (params: ILoginParams) => Promise<void>;
  register: (params: IRegisterParams) => Promise<void>;
  getUserInfo: () => Promise<void>;
  logout: () => Promise<void>;
  refreshUserBalance: () => Promise<void>;
  reset: () => void;
  initialize: (userInfo: IUserInfo | null) => Promise<void>;
  checkAuth: () => Promise<boolean>;
  updateUserInfo: (userInfo: Partial<IUserInfo>) => void;
}

const createAuthSlice: StateCreator<AuthState> = (set, get) => ({
  isFetchingBalance: false,
  token: undefined,
  refreshToken: undefined,
  userInfo: undefined,
  isAuthenticated: false,
  isInitialized: false,

  initialize: async (userInfo: IUserInfo | null) => {
    try {
      if (userInfo) {
        set({ isAuthenticated: true, isInitialized: true, userInfo });
      } else {
        get().reset();
      }
    } catch {
      get().reset();
    } finally {
      set({ isInitialized: true });
    }
  },

  checkAuth: async () => {
    try {
      await get().getUserInfo();
      return true;
    } catch {
      get().reset();
      return false;
    }
  },

  login: async (params) => {
    const response = await postLoginApi(params);
    setCookie("authToken", response.accessToken, 365);
    set({
      token: response.accessToken,
      refreshToken: response.refreshToken,
      isAuthenticated: true,
    });
    await get().getUserInfo();
  },

  register: async (params) => {
    const response = await postRegisterApi(params);
    set({
      token: response.accessToken,
      refreshToken: response.refreshToken,
      isAuthenticated: true,
    });
    await get().getUserInfo();
    setCookie("authToken", response.accessToken, 365);
  },

  getUserInfo: async () => {
    const response = await getUserInfoApi();
    set({ userInfo: response });
  },

  logout: async () => {
    try {
      await getLogoutApi();
    } finally {
      get().reset();
      window.location.href = PATH_NAME.SIGN_IN;
    }
  },

  refreshUserBalance: async () => {
    set({ isFetchingBalance: true });
    try {
      const newBalance = await getRefreshUserBalanceApi();
      set((state) => ({
        userInfo: state.userInfo
          ? { ...state.userInfo, balance: newBalance }
          : undefined,
      }));
    } finally {
      set({ isFetchingBalance: false });
    }
  },

  reset: () => {
    set({
      token: undefined,
      refreshToken: undefined,
      userInfo: undefined,
      isAuthenticated: false,
      isFetchingBalance: false,
    });
    removeCookie("authToken");
    removeCookie("userInfo");
  },

  updateUserInfo: (userInfo: Partial<IUserInfo>) => {
    set((state) => ({
      userInfo: state.userInfo ? { ...state.userInfo, ...userInfo } : undefined,
    }));
  },
});

export const createAuthStore = () =>
  create<AuthState>()(
    persist(createAuthSlice, {
      name: "auth-storage",
    })
  );
