import { MENU_ITEMS } from "@/constants/header-menu-items";
import { NavigationItem } from "./NavigationItem";
import { useAuthStore } from "@/providers/auth-store-provider";

interface NavigationItemsProps {
  pathname: string;
}

export const NavigationItems = ({ pathname }: NavigationItemsProps) => {
  const { userInfo } = useAuthStore((state) => state);

  return (
    <ul className="main-nav navbar-nav">
      {MENU_ITEMS.map((item) => {
        if (
          (item.key === "dashboard" || item.key === "createTask") &&
          !userInfo
        ) {
          return null;
        }
        return (
          <NavigationItem key={item.key} item={item} pathname={pathname} />
        );
      })}
    </ul>
  );
};
