import { useMediaQuery } from "react-responsive";

import { MAX_CONTENT_WIDTH } from "@/constants/common.constant";

const useSmallMobileScreen = () => useMediaQuery({ maxWidth: 375 });
const useMobileScreen = () => useMediaQuery({ maxWidth: 640 });
const useTabletScreen = () => useMediaQuery({ minWidth: 641, maxWidth: 1224 });
const useMobileOrTabletScreen = () => useMediaQuery({ maxWidth: 1224 });
const useDesktopScreen = () => useMediaQuery({ minWidth: 1225 });
const useBigScreen = () => useMediaQuery({ minWidth: MAX_CONTENT_WIDTH + 1 });

export {
  useSmallMobileScreen,
  useMobileScreen,
  useTabletScreen,
  useMobileOrTabletScreen,
  useDesktopScreen,
  useBigScreen,
  useMediaQuery,
};
