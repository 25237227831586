"use client";
import { useAuthStore } from "@/providers/auth-store-provider";
import { getCookie } from "@/utils/cookies";
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { Logo } from "./components/Logo";
import { MainMenu } from "./components/MainMenu";
import { RightNavigation } from "./components/RightNavigation";

const Header = () => {
  const pathname = usePathname();
  const [isFixed, setIsFixed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [isLoading, setIsLoading] = useState(true);
  const { initialize } = useAuthStore((state) => state);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window?.scrollY > 0);
    };
    window?.addEventListener("scroll", handleScroll);

    const fetchUserInfo = () => {
      const userInfo = getCookie("userInfo");
      const parsedUserInfo =
        typeof userInfo === "string" ? JSON.parse(userInfo) : null;
      initialize(parsedUserInfo?.data ?? null);
    };

    fetchUserInfo();
    setIsLoading(false);
    return () => window?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    !isLoading && (
      <div className={clsx("main-wrapper", isModalOpen && "menu-opened")}>
        <header className={clsx("header", isFixed && "fixed")}>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg header-nav">
              <Logo toggleModal={toggleModal} />
              <MainMenu toggleModal={toggleModal} pathname={pathname} />
              <RightNavigation />
            </nav>
          </div>
        </header>
      </div>
    )
  );
};

export default Header;
