import NextImage from "@/components/Common/NextImage";
import { PATH_NAME } from "@/constants/path-name.constant";
import { IUserInfo } from "@/types/auth.types";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import UserMenuDropdown from "./UserMenuDropdown";

export const UserDashboardNav = ({ userInfo }: { userInfo: IUserInfo }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const menuRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

  return (
    <ul className="nav header-navbar-rht">
      <li className="nav-item logged-item noti-nav noti-wrapper">
        <Link href="#" className="nav-link" data-bs-toggle="dropdown">
          <span className="bell-icon">
            <NextImage
              src="/assets/img/icons/bell-icon.svg"
              alt="Bell"
              width={14}
              height={14}
            />
          </span>
          <span className="badge badge-pill" />
        </Link>
        <NotificationsDropdown />
      </li>

      <li
        ref={menuRef}
        className={`nav-item dropdowns has-arrow logged-item ${
          isUserMenuOpen ? "is-active" : ""
        }`}
      >
        <div
          className="nav-link toggle position-relative"
          onClick={toggleUserMenu}
        >
          <span className="log-user dropdown-toggle">
            <span className="users-img">
              <NextImage
                className="rounded-circle"
                src={userInfo?.profilePicture || ""}
                alt="Profile"
                width={40}
                height={40}
              />
            </span>
            <span className="user-text ms-1">{userInfo?.displayName}</span>
          </span>
        </div>
        <UserMenuDropdown />
      </li>
    </ul>
  );
};

const NotificationsDropdown = () => {
  const t = useTranslations("common");
  return (
    <div className="dropdown-menu dropdown-menu-end noti-blk">
      <div className="topnav-dropdown-header">
        <div>
          <p className="notification-title">{t("notifications")}</p>
        </div>
        <Link href="#" className="mark-all-noti">
          Mark all as read <i className="feather icon-check-square" />
        </Link>
      </div>
      <NotificationsList />
      <div className="clear-all-noti">
        {/* <Link className="clear-notification" href={PATH_NAME.USER_NOTIFICATION}>
          {t("viewAll")}
        </Link> */}
      </div>
    </div>
  );
};

const NotificationsList = () => (
  <ul>
    {/* Example notification item */}
    <li className="notification-message">
      <div className="media noti-img d-flex">
        <Link href={PATH_NAME.USER_NOTIFICATION} className="active-noti">
          <span className="avatar avatar-sm flex-shrink-0">
            <NextImage
              className="avatar-img rounded-circle"
              alt="User Image"
              src="/assets/img/user/user-01.jpg"
              width={32}
              height={32}
            />
          </span>
        </Link>
        <div className="media-body flex-grow-1">
          <Link href={PATH_NAME.USER_NOTIFICATION}>
            <h6 className="noti-details">
              Lex Murphy <span>requested access to </span> UNIX directory tree
              hierarchy
            </h6>
          </Link>
          <div className="notify-btns">
            <button className="btn btn-sm btn-primary">Accept</button>
            <button className="btn btn-sm btn-secondary">Decline</button>
          </div>
          <p className="noti-time">
            <span className="notification-time">Today at 9:42 AM</span>
          </p>
        </div>
      </div>
    </li>
  </ul>
);
