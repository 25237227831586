import { PATH_NAME } from "@/constants/path-name.constant";
import { useTranslations } from "next-intl";
import Link from "next/link";

export const AuthNav = () => {
  const t = useTranslations();
  return (
    <ul className="nav header-navbar-rht">
      <li className="nav-item">
        <Link className="btn btn-secondary" href={PATH_NAME.SIGN_IN}>
          {t("common.signIn")}
        </Link>
      </li>
      <li className="nav-item">
        <Link className="btn btn-primary" href={PATH_NAME.SIGN_UP}>
          {t("common.getStarted")}
        </Link>
      </li>
    </ul>
  );
};
