import http from "@/config/fetch.config";
import {
  ILoginParams,
  ILoginResponse,
  IRegisterParams,
  IUserInfo,
} from "@/types/auth.types";

export const postLoginApi = async ({
  account,
  password,
}: ILoginParams): Promise<ILoginResponse> => {
  const response = await http.post<ILoginResponse>("/service/api/user/login", {
    account,
    password,
  });

  return response.data;
};

export const getLogoutApi = async () => {
  await http.get("/service/api/user/logout");
};

export const postRegisterApi = async (params: IRegisterParams) => {
  const response = await http.post<ILoginResponse>(
    "/service/api/user/register",
    params
  );
  return response.data;
};

export const getUserInfoApi = async () => {
  const response = await http.get<IUserInfo>("/service/api/user/info");
  return response.data;
};

export const getRefreshUserBalanceApi = async () => {
  const response = await http.get<number>("/service/api/user/refreshBalance");
  return response.data;
};

export const postChangePasswordApi = async (params: {
  oldPassword: string;
  newPassword: string;
  passwordValid: string;
}) => {
  const response = await http.post("/service/api/user/updatePw", params);
  return response.data;
};

export const postUpdateUserInfoApi = async (params?: IUserInfo) => {
  const response = await http.post("/service/api/user/updateInfo", params);
  return response.data;
};
