"use client";
import isNumber from "lodash/isNumber";
import Image, { ImageLoaderProps, ImageProps } from "next/image";
import { useCallback, useMemo, useState } from "react";

import {
  useMobileScreen,
  useSmallMobileScreen,
  useTabletScreen,
} from "@/hooks/useMediaQuery";

import LoadFailedImage from "../../../../public/assets/other/image-failed-default.png";

const myLoader = (props: ImageLoaderProps) => {
  return `${props.src}?w=${props.width}&q=${props.quality || 75}`;
};

const NextImage: React.FC<
  ImageProps & {
    scaleForSmallDevice?: boolean;
    scaleSize?: number;
    smallDeviceScaleSize?: number;
    tabletScaleSize?: number;
    loadFailedImageUrl?: string;
  }
> = ({
  loadFailedImageUrl = LoadFailedImage,
  scaleForSmallDevice = true,
  scaleSize = 0.85,
  smallDeviceScaleSize = 0.75,
  tabletScaleSize = 0.9,
  src,
  loader = myLoader,
  width,
  height,
  alt = "",
  ...rest
}) => {
  const [imageState, setImageState] = useState({
    src: src || loadFailedImageUrl,
    width: width,
    height: height,
  });

  const isSmallDevice = useSmallMobileScreen();
  const isMobile = useMobileScreen();
  const isTablet = useTabletScreen();

  const getScaledDimension = useCallback(
    (dimension: number | undefined) => {
      if (scaleForSmallDevice && isNumber(dimension)) {
        const scaled = scaleSize * Number(dimension);

        if (isSmallDevice) return smallDeviceScaleSize * scaled;
        if (isMobile) return scaled;
        if (isTablet) return tabletScaleSize * Number(dimension);
      }
      return dimension;
    },
    [
      isSmallDevice,
      isMobile,
      isTablet,
      scaleForSmallDevice,
      scaleSize,
      smallDeviceScaleSize,
      tabletScaleSize,
    ]
  );

  const scaledDimensions = useMemo(
    () => ({
      width: getScaledDimension(Number(imageState.width)),
      height: getScaledDimension(Number(imageState.height)),
    }),
    [getScaledDimension, imageState.width, imageState.height]
  );

  const DEFAULT_SIZES = useMemo(
    () =>
      "(max-width: 768px) 100vw, (max-width: 1024px) 75vw, (max-width: 1280px) 50vw, 30vw",
    []
  );

  return (
    <Image
      src={imageState.src}
      onError={() =>
        setImageState((prev) => ({ ...prev, src: loadFailedImageUrl }))
      }
      alt={alt}
      width={scaledDimensions.width}
      height={scaledDimensions.height}
      placeholder={
        (!!imageState.width && Number(imageState.width) < 40) ||
        (!!imageState.height && Number(imageState.height) < 40)
          ? "empty"
          : "blur"
      }
      blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      sizes={
        isNumber(imageState.width) && Number(imageState.width) <= 100
          ? `${imageState.width}px`
          : DEFAULT_SIZES
      }
      quality={
        isNumber(imageState.width) && Number(imageState.width) >= 1800 ? 65 : 75
      }
      loader={loader}
      {...rest}
      suppressHydrationWarning={true}
    />
  );
};

export default NextImage;
