"use client";
import { useEffect, useState } from "react";

const Cursor = () => {
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    // Find elements with the specified classes
    const e = document.querySelector(".cursor-inner") as HTMLElement;
    const t = document.querySelector(".cursor-outer") as HTMLElement;

    const o = false;

    if (typeof window !== "undefined") {
      window.onmousemove = function (s: MouseEvent) {
        if (!o) {
          t.style.transform =
            "translate(" + s.clientX + "px, " + s.clientY + "px)";
        }
        e.style.transform =
          "translate(" + s.clientX + "px, " + s.clientY + "px)";
      };
    }

    e.style.visibility = "visible";
    t.style.visibility = "visible";

    const anchor = document.getElementsByTagName("a");
    Array.from(anchor).map((item: HTMLElement) => {
      item.addEventListener("mouseover", () => {
        setIsHovered(true);
      });
      item.addEventListener("mouseout", () => {
        setIsHovered(false);
      });
    });
  }, [isHovered]);

  return (
    <>
      <div
        className={`mouse-cursor cursor-outer ${
          isHovered ? "cursor-hover" : ""
        }`}
      ></div>
      <div
        className={`mouse-cursor cursor-inner ${
          isHovered ? "cursor-hover" : ""
        }`}
      ></div>
    </>
  );
};

export default Cursor;
