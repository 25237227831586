import dayjs from "dayjs";

export const formatMoney = (money?: number) => {
  if (money == null) {
    return money;
  } else {
    const format = money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return format;
  }
};

export const formatTextToSlug = (text?: string) => {
  if (!text) return "";

  return (
    text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "") +
    "-" +
    dayjs().unix()
  );
};

export const formatTax = (tax?: number) => {
  if (typeof tax !== "number") return tax;
  return tax * 100 + "%";
};

export const formatTimeAgo = (
  timestamp: string | number | undefined,
  t: (key: string) => string
) => {
  const timezone = "Asia/Bangkok";
  if (!dayjs(timestamp, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    return "Invalid Date";
  }
  const serverTime = dayjs.tz(timestamp, timezone);
  const localTime = dayjs().tz(timezone);
  const diffInSeconds = localTime.diff(serverTime, "second");

  if (diffInSeconds < 60) {
    return 0 + `${" " + t("time.minutes") + " " + t("time.ago")}`;
  } else if (diffInSeconds < 3600) {
    return (
      Math.floor(diffInSeconds / 60) +
      `${" " + t("time.minutes") + " " + t("time.ago")}`
    );
  } else if (diffInSeconds < 86400) {
    return (
      Math.floor(diffInSeconds / 3600) +
      `${" " + t("time.hours") + " " + t("time.ago")}`
    );
  } else if (diffInSeconds < 2592000) {
    return (
      Math.floor(diffInSeconds / 86400) +
      `${" " + t("time.days") + " " + t("time.ago")}`
    );
  } else if (diffInSeconds < 31536000) {
    return (
      Math.floor(diffInSeconds / 2592000) +
      `${" " + t("time.months") + " " + t("time.ago")}`
    );
  } else {
    return (
      Math.floor(diffInSeconds / 31536000) +
      `${" " + t("time.years") + " " + t("time.ago")}`
    );
  }
};

export const formatCurrency = (amount: number) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " VNĐ";
};
